import './Styles/video.css';
import React from 'react';
import styled from 'styled-components';
import Apple from './Assets/apple.png';
import Google from './Assets/google.png';
// import Phone from './Assets/phone.png';
import Logo from './Assets/logo.png';
import VidBg from './Assets/video.mp4';
export const TABLET = '768px';

// #region Component Styles
const Container = styled.div`
  position: relative;
  overflow: hidden;
  /* background-color: rgb(94, 23, 235); */
  color: white;
  display: block;

  @media screen and (min-width: ${TABLET}) {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    /* border-radius: 45px; */
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  @media only screen and (min-width: 834px) and (max-width: 1194px) {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    /* border-radius: 45px; */
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
`;
const Div1 = styled.div`
  padding: 2rem;
  width: 100%;
  height: 100%;
  @media screen and (min-width: ${TABLET}) {
    padding: 5em;
    width: 75%;
    margin: 0;
    justify-content: center;
    display: block;
    align-items: center;
  }
`;
const Div2 = styled.div`
  padding: 0px;
  width: 100%;
  @media screen and (min-width: ${TABLET}) {
    padding: 5em;
    width: 25%;
    display: flex;
    align-items: center;
  }
`;

const Title = styled.h1`
  padding-top: 55%;
  font-size: 2.1em;
  @media screen and (min-width: ${TABLET}) {
    padding-top: 2em;
    font-size: 3em;
    font-weight: 500;
  }
`;

const Subtitle = styled.h2`
  font-weight: 600;
  font-size: 2em;

  @media screen and (min-width: ${TABLET}) {
    font-size: 3.8em;
  }
`;

const Paragraph = styled.h3`
  font-size: 1.5em;
  color: white;
  font-weight: 500;
  width: auto;
  @media screen and (min-width: ${TABLET}) {
    font-size: 2.1em;
    color: white;
    font-weight: 500;
    width: auto;
  }
`;

const BtnDiv = styled.div`
  display: block;
  align-self: center;
  @media screen and (min-width: ${TABLET}) {
    display: flex;
    align-self: center;
  }

  @media only screen and (min-width: 834px) and (max-width: 1194px) {
    display: block;
    align-self: center;
  }
`;

const Button = styled.button`
margin-top: 1em;
  padding: 1em 1em;
  border-radius: 2em;
  outline: none;
  border: none;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-right: 1.5em;
  font-weight: 500;
  font-size: 1.2em;
  font-family: Inter, sans-serif;
  color: black;
  background-color: rgb(255, 255, 255);
  :hover {
    background-color: black;
    color: white;
  }
`;

const Logos = styled.img`
  height: 1.25em;
  width: 1.25em;
  margin-right: 0.5em;
`;

const HeaderImg = styled.img`
  display: none;
  @media screen and (min-width: ${TABLET}) {
    padding: 4em;
    display: initial;
  }
  @media only screen and (min-width: 834px) and (max-width: 1194px) {
    width: 300px;
    display: initial;
  }
`;
// #endregion

const Content = () => (
  <>
    <Container>
      <video autoPlay loop muted height="100%" width="100%" id="video">
        <source src={VidBg} type="video/mp4" />
      </video>
      <Div1>
        <Title>BlockOTP</Title>
        <Subtitle>2FA on the Blockchain</Subtitle>
        <Paragraph>Website is under construction</Paragraph>

        <BtnDiv>
          <Button>
            <Logos src={Apple} />
            Coming Soon
          </Button>
          <Button>
            <Logos src={Google} />
            Coming Soon
          </Button>
        </BtnDiv>
      </Div1>
      <Div2>
        <HeaderImg src={Logo} />
      </Div2>
    </Container>
  </>
);

export default Content;
